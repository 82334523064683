// ==================================================
//  メインビジュアル
// ==================================================
document.addEventListener("DOMContentLoaded", function () {
  setTimeout(function () {
    const mainvisual = document.querySelector(".js-mainvisual");

    if (mainvisual) {
      mainvisual.classList.add("is-active");
    }
  }, 1500);
});

// ==================================================
//  スライダー01/02
// ==================================================
document.addEventListener("DOMContentLoaded", function () {
  const sliderOptions = {
    type: "loop",
    drag: "free",
    focus: "center",
    arrows: false,
    pagination: false,
    gap: 40,
    perPage: 3,
    fixedWidth: "480px",
    autoScroll: {
      speed: 0.5,
      pauseOnHover: false,
      pauseOnFocus: false,
    },
    breakpoints: {
      767: {
        gap: 15,
        fixedWidth: "240px",
      },
    },
  };

  // 01
  if (document.getElementById("home-slider-01")) {
    new Splide("#home-slider-01", sliderOptions).mount(window.splide.Extensions);
  }

  // 02
  if (document.getElementById("home-slider-02")) {
    new Splide("#home-slider-02", sliderOptions).mount(window.splide.Extensions);
  }
});

// ==================================================
//  テキストループ
// ==================================================
document.addEventListener("DOMContentLoaded", () => {
  const marqueeText = document.querySelector(".p-home-about__marqueeText").textContent;
  const contentElements = document.querySelectorAll(".p-home-about__marqueeContent");

  // すべてのコンテンツ要素に同じテキストを設定
  contentElements.forEach((element) => {
    element.innerHTML = `<span class="p-home-about__marqueeText">${marqueeText}</span>`;
  });

  // テキストの幅を計算して、アニメーション速度を調整する関数
  const adjustAnimationSpeed = () => {
    const textWidth = contentElements[0].offsetWidth;
    const duration = textWidth / 30; // 速度調整のための係数

    contentElements.forEach((element) => {
      element.style.animationDuration = `${duration}s`;
    });
  };

  // 初期調整
  adjustAnimationSpeed();

  // リサイズ時に再調整
  window.addEventListener("resize", adjustAnimationSpeed);
});
